@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* From Figma */
    --brand-50: 202 100% 95%;
    --brand-100: 209 100% 91%;
    --brand-200: 206 100% 85%;
    --brand-400: 207 98% 66%;
    --brand-600: 215 87% 51%;
    --brand-700: 218 80% 46%;
    --brand-800: 220 75% 38%;
    --brand-900: 218 68% 31%;
    --brand-950: 218 69% 20%;

    --gray-50: 210 20% 98%;
    --gray-100: 216 24% 96%;
    --gray-200: 220 17% 93%;
    --gray-300: 217 16% 84%;
    --gray-400: 218 15% 65%;
    --gray-500: 221 13% 46%;
    --gray-600: 216 18% 34%;
    --gray-700: 217 24% 27%;
    --gray-800: 216 32% 14%;
    --gray-900: 220 43% 11%;
    --gray-950: 222 41% 8%;

    --orange-50: 29 90% 96%;
    --orange-200: 36 86% 83%;
    --orange-700: 13 80% 40%;

    --pink-50: 319 75% 97%;
    --pink-200: 318 87% 90%;
    --pink-400: 321 88% 70%;
    --pink-700: 327 80% 42%;

    --violet-400: 254 93% 76%;

    --success-50: 144 78% 96%;
    --success-100: 141 74% 92%;
    --success-200: 144 69% 80%;
    --success-400: 150 57% 54%;
    --success-500: 152 77% 39%;
    --success-700: 155 91% 24%;

    --error-50: 4 98% 89%;
    --error-200: 4 88% 97%;
    --error-400: 4 93% 69%;
    --error-500: 4 86% 58%;
    --error-700: 4 76% 40%;

    --warning-400: 39 98% 56%;
    --warning-500: 34 94% 50%;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
